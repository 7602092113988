<template>
  <div class="pb-5 mb-5 bg-white p-2">
    <h2 class="mb-4">Form components examples</h2>
    <div class="px-4">
      <h4>Color picker</h4>
      <div class="mb-2 d-flex">
        <div class="me-6">
          <ColorPicker input-label="Color" invalid-feedback="" />
        </div>
        <div class="me-6">
          <ColorPicker
            input-label="Color error"
            invalid-feedback="Wrong color"
          />
        </div>
      </div>
      <div class="d-flex">
        <div class="me-6">
          <div>
            <h4>Inputs</h4>
            <div>Placeholder</div>
            <InputField placeholder="Placeholder"></InputField>
            <div class="mt-2">Has Value</div>
            <InputField v-model="state.testString"></InputField>
            <div class="mt-2">Error / Has Value</div>
            <InputField
              invalid-feedback="Error message"
              v-model="state.testString"
            ></InputField>
            <div class="mt-2">Error / Placeholder</div>
            <InputField
              invalid-feedback="Error message"
              placeholder="Placeholder"
            ></InputField>
            <div class="mt-2">Disabled / Has Value</div>
            <InputField
              :disabled="true"
              v-model="state.testString"
            ></InputField>
            <div class="mt-2">Disabled / Placeholder</div>
            <InputField :disabled="true" placeholder="Placeholder"></InputField>
          </div>
        </div>
        <div class="me-6">
          <div>
            <h4>Textarea</h4>
            <div>Placeholder</div>
            <InputField type="textarea" placeholder="Placeholder"></InputField>
            <div class="mt-2">Has Value</div>
            <InputField type="textarea" v-model="state.testString"></InputField>
            <div class="mt-2">Error / Has Value</div>
            <InputField
              type="textarea"
              invalid-feedback="Error message"
              v-model="state.testString"
            ></InputField>
            <div class="mt-2">Error / Placeholder</div>
            <InputField
              type="textarea"
              invalid-feedback="Error message"
              placeholder="Placeholder"
            ></InputField>
            <div class="mt-2">Disabled / Has Value</div>
            <InputField
              type="textarea"
              :disabled="true"
              v-model="state.testString"
            ></InputField>
            <div class="mt-2">Disabled / Placeholder</div>
            <InputField
              type="textarea"
              :disabled="true"
              placeholder="Placeholder"
            ></InputField>
          </div>
        </div>
        <div style="min-width: 260px" class="me-6">
          <div>
            <h4>Selects</h4>
            <div>Placeholder</div>
            <CustomSelect
              :filterable="true"
              :close-on-select="true"
              :options="state.testOptions"
              type="textarea"
              placeholder="Select one"
            ></CustomSelect>
            <div class="mt-2">Has Value</div>
            <CustomSelect
              :filterable="true"
              :close-on-select="true"
              :options="state.testOptions"
              type="textarea"
              v-model="state.testObject"
            ></CustomSelect>
            <div class="mt-2">Error / Has Value</div>
            <CustomSelect
              :filterable="true"
              :close-on-select="true"
              :options="state.testOptions"
              type="textarea"
              invalid-feedback="Error message"
              v-model="state.testObject"
            ></CustomSelect>
            <div class="mt-2">Error / Placeholder</div>
            <CustomSelect
              :filterable="true"
              :close-on-select="true"
              :options="state.testOptions"
              type="textarea"
              invalid-feedback="Error message"
              placeholder="Placeholder"
            ></CustomSelect>
            <div class="mt-2">Disabled / Has Value</div>
            <CustomSelect
              :close-on-select="true"
              :options="state.testOptions"
              type="textarea"
              :disabled="true"
              v-model="state.testObject"
            ></CustomSelect>
            <div class="mt-2">Disabled / Placeholder</div>
            <CustomSelect
              :close-on-select="true"
              :options="state.testOptions"
              type="textarea"
              :disabled="true"
              placeholder="Placeholder"
            ></CustomSelect>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="d-inline-block" style="min-width: 500px">
          <h4>Multiple select</h4>
          <CustomSelect
            :filterable="true"
            :deselect-from-dropdown="true"
            :options="state.testOptions"
            v-model="state.testMultipleSelect"
            type="textarea"
            :multiple="true"
            placeholder="Select Multiple"
          ></CustomSelect>
        </div>
      </div>

      <div class="d-flex">
        <div class="mt-4">
          <h4>Date picker</h4>
          <div>Default</div>
          <CustomDatePicker
            v-model="state.testDatePicker"
            placeholder="Select Multiple"
          ></CustomDatePicker>
          <div class="mt-2">Invalid</div>
          <CustomDatePicker
            invalid-feedback="Error message"
            v-model="state.testDatePicker"
            placeholder="Select Multiple"
          ></CustomDatePicker>
          <div class="mt-2">Disabled</div>
          <CustomDatePicker
            :disabled="true"
            v-model="state.testDatePicker"
            placeholder="Select Multiple"
          ></CustomDatePicker>

          <CustomSelectDatePicker
            v-model="state.form.selectedDateRange"
            :date-ranges="state.optionalDateRanges"
            @update:model-value="onDateRangeChange"
          ></CustomSelectDatePicker>
        </div>
        <div class="mt-4 ms-6">
          <h4>Time picker</h4>
          <div>Default</div>
          <CustomTimePicker
            format="HH:mm"
            v-model="state.testTimePicker"
            placeholder="Select Multiple"
          ></CustomTimePicker>
          <div class="mt-2">Invalid</div>
          <CustomTimePicker
            format="HH:mm"
            invalid-feedback="Error message"
            v-model="state.testTimePicker"
            placeholder="Select Multiple"
          ></CustomTimePicker>
          <div class="mt-2">Disabled</div>
          <CustomTimePicker
            :readonly="true"
            v-model="state.testTimePicker"
            placeholder="Select Multiple"
          ></CustomTimePicker>
        </div>
      </div>
      <div class="mt-4 mb-6">
        <div class="d-flex align-items-start">
          <div class="me-6">
            <h4>Checkboxes</h4>
            <div>Default</div>
            <CustomCheck class="me-2"></CustomCheck>
            <CustomCheck :model-value="true"></CustomCheck>
            <div>Disabled</div>
            <CustomCheck :disabled="true" class="me-2"></CustomCheck>
            <CustomCheck :disabled="true" :model-value="true"></CustomCheck>
          </div>
          <div class="me-6">
            <h4>Radio</h4>
            <div>Default</div>
            <CustomCheck name="radio" type="radio" class="me-2"></CustomCheck>
            <CustomCheck
              name="radio"
              type="radio"
              :model-value="true"
            ></CustomCheck>
            <div>Disabled</div>
            <CustomCheck
              type="radio"
              :disabled="true"
              class="me-2"
            ></CustomCheck>
            <CustomCheck
              type="radio"
              :disabled="true"
              :model-value="true"
            ></CustomCheck>
          </div>
          <div class="me-6">
            <h4>Switch</h4>
            <div>Default</div>
            <CustomSwitch type="radio" class="me-2"></CustomSwitch>
            <CustomSwitch type="radio" :model-value="true"></CustomSwitch>
            <div>Disabled</div>
            <CustomSwitch
              type="radio"
              :disabled="true"
              class="me-2"
            ></CustomSwitch>
            <CustomSwitch
              type="radio"
              :disabled="true"
              :model-value="true"
            ></CustomSwitch>
          </div>
        </div>
        <div class="py-4">
          <h4>Action buttons</h4>
          <Actions :actions="state.testActions"></Actions>
        </div>
      </div>
      <div class="d-flex align-items-start justify-content-center me-6">
        <Pagination />
        <Perpage />
      </div>
    </div>

    <h2>Buttons</h2>
    <div class="px-4">
      <div class="d-flex">
        <div class="me-6">
          <div>
            <h4>Base button</h4>
            <div class="mb-2">Default</div>
            <div class="d-flex align-items-center">
              <BaseButton class="me-3" rounded>Button</BaseButton>
              <BaseButton rounded prepend-icon="ri-add-line">Button</BaseButton>
            </div>
            <div class="mb-2 mt-4">Disabled</div>
            <div class="d-flex align-items-center">
              <BaseButton :disabled="true" class="me-3" rounded
                >Button</BaseButton
              >
              <BaseButton :disabled="true" rounded prepend-icon="ri-add-line"
                >Button</BaseButton
              >
            </div>
          </div>
        </div>
        <div class="me-6">
          <div>
            <h4>Solid button</h4>
            <div class="mb-2">Default</div>
            <div class="d-flex align-items-center">
              <BaseButton class="me-3" rounded solid>Button</BaseButton>
              <BaseButton rounded solid prepend-icon="ri-add-line"
                >Button</BaseButton
              >
            </div>
            <div class="mb-2 mt-4">Disabled</div>
            <div class="d-flex align-items-center">
              <BaseButton :disabled="true" class="me-3" rounded solid
                >Button</BaseButton
              >
              <BaseButton
                :disabled="true"
                rounded
                solid
                prepend-icon="ri-add-line"
                >Button</BaseButton
              >
            </div>
          </div>
        </div>
        <div class="me-6">
          <div>
            <h4>Solid gradient</h4>
            <div class="mb-2">Default</div>
            <div class="d-flex align-items-center">
              <BaseButton class="me-3 solid-gradient" rounded solid
                >Button</BaseButton
              >
              <BaseButton
                class="solid-gradient"
                rounded
                solid
                prepend-icon="ri-add-line"
                >Button</BaseButton
              >
            </div>
            <div class="mb-2 mt-4">Disabled</div>
            <div class="d-flex align-items-center">
              <BaseButton
                :disabled="true"
                class="me-3 solid-gradient"
                rounded
                solid
                >Button</BaseButton
              >
              <BaseButton
                class="solid-gradient"
                :disabled="true"
                rounded
                solid
                prepend-icon="ri-add-line"
                >Button</BaseButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 px-4">
      <div class="d-flex">
        <div class="me-6">
          <div>
            <h4>Button Pills</h4>
            <div class="mb-2">Default</div>
            <div class="d-flex align-items-center">
              <PillButton class="me-3" rounded-start>Button</PillButton>
              <PillButton class="me-3">Button</PillButton>
              <PillButton rounded-end class="me-3">Button</PillButton>
              <PillButton rounded class="me-3">Button</PillButton>
            </div>
            <div class="mb-2 mt-4">Active</div>
            <div class="d-flex align-items-center">
              <PillButton active class="me-3" rounded-start>Button</PillButton>
              <PillButton active class="me-3">Button</PillButton>
              <PillButton active rounded-end class="me-3">Button</PillButton>
              <PillButton active rounded class="me-3">Button</PillButton>
            </div>
          </div>
          <div class="mt-3">
            <h4>Icon buttons</h4>
            <div class="mb-2">Default</div>
            <div class="d-flex align-items-center">
              <IconButton icon="ri-volume-up-fill" class="me-3"
                >Button</IconButton
              >
            </div>
            <div class="mb-2 mt-4">Active</div>
            <div class="d-flex align-items-center">
              <IconButton active icon="ri-volume-up-fill" class="me-3"
                >Button</IconButton
              >
            </div>
            <div class="mb-2 mt-4">Disabled</div>
            <div class="d-flex align-items-center">
              <IconButton disabled icon="ri-volume-up-fill" class="me-3"
                >Button</IconButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: #f9fafb" class="mt-3 p-4">
      <h4>Datatable action panel</h4>
      <ActionPanel
        show-sort
        show-export
        show-search
        :tabs="[
          { label: 'My passes', value: 'mypasses' },
          { label: 'All passes', value: 'all' }
        ]"
      ></ActionPanel>
    </div>

    <div class="mt-3">
      <div class="mb-4">
        <h4>Modal Examples</h4>
        <CRow>
          <CCol>
            <button
              class="v3-base-btn full-rounded me-2"
              @click="
                openModal({
                  visible: true,
                  size: 'sm',
                  title: 'Test modal: Small'
                })
              "
            >
              Open small modal
            </button>
            <button
              class="v3-base-btn full-rounded me-2"
              @click="
                openModal({
                  visible: true,
                  size: 'md',
                  title: 'Test modal: Medium'
                })
              "
            >
              Open medium modal
            </button>
            <button
              class="v3-base-btn full-rounded me-2"
              @click="
                openModal({
                  visible: true,
                  size: 'lg',
                  title: 'Test modal: Large'
                })
              "
            >
              Open large modal
            </button>
            <button
              class="v3-base-btn full-rounded me-2"
              @click="
                openModalForm({
                  visible: true,
                  size: 'md',
                  title: 'Appointments Pass Pop-up'
                })
              "
            >
              Open Modal with component inside (example)
            </button>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, inject } from "vue"
import * as FormComponents from "../v3components/shared/Form"
import Pagination from "../v3components/shared/DataTable/Pagination.vue"
import Perpage from "../v3components/shared/DataTable/Perpage.vue"
import * as Buttons from "../v3components/shared/Buttons"
import ActionPanel from "../v3components/shared/DataTable/ActionPanel.vue"
import CreateAppoinmentsPassForm from "@/components/Forms/CreateAppoinmentsPassForm.vue"
import Actions from "../v3components/shared/DataTable/Actions.vue"
import CustomSelectDatePicker from "../v3components/shared/Form/CustomSelectDatePicker.vue"
import moment from "moment-timezone"
import helpers from "../helpers/index.js"

export default {
  components: {
    ...FormComponents,
    ...Buttons,
    Pagination,
    Perpage,
    ActionPanel,
    Actions,
    CustomSelectDatePicker
  },
  name: "FormComponentsExamples",
  setup() {
    const state = reactive({
      testString: "Test value",
      testObject: { label: "Test value", value: 1 },
      testMultipleSelect: [],
      testDatePicker: null,
      testTimePicker: null,
      testOptions: [
        { label: "Test value", value: 1 },
        { label: "Test value 2", value: 2 },
        { label: "Test value 3", value: 3 },
        { label: "Test value 4", value: 4 },
        { label: "Test value 5", value: 5 }
      ],
      testActions: [
        { label: "Edit", icon: "ri-checkbox-circle-line" },
        { label: "Activate", icon: "ri-edit-line" },
        { label: "Delete", icon: "ri-delete-bin-line", class: "important" }
      ],
      optionalDateRanges: [
        {
          label: "Today",
          id: helpers.stringDateRange(moment(), moment())
        },
        {
          label: "Yesterday",
          id: helpers.stringDateRange(
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          )
        },
        {
          label: "Last 7 days",
          id: helpers.stringDateRange(moment().subtract(7, "days"), moment())
        },
        {
          label: "Last 14 days",
          id: helpers.stringDateRange(moment().subtract(14, "days"), moment())
        },
        {
          label: "Custom Range",
          id: "custom"
        }
      ],
      form: {
        selectedDateRange: null
      }
    })
    const modal = inject("modal")
    const modalTestComponent = {
      template: `
        <h3>This is a modal test content</h3>
      `
    }
    const openModal = (options) => {
      modal.open(modalTestComponent, options)
    }
    const openModalForm = (options) => {
      modal.open(CreateAppoinmentsPassForm, options)
    }

    return {
      state,
      openModal,
      openModalForm
    }
  }
}
</script>

<style></style>
