<template>
  <CSidebar
    overlaid
    container="lg"
    position="fixed"
    :key="componentKey"
    :class="{ hide: !show }"
    class="the-sidebar sidebar-lg-show v3-the-sidebar"
    :unfoldable="false"
    :visible="show"
    @visible-change="
      (value) => $store.commit('layout/set', ['sidebarShow', value])
    "
  >
    <div class="v3-sidebar-header" tabindex="-1">
      <div
        class="v3-brand-container text-center"
        :to="userRole == 'student' ? '/passes/create/' : '/'"
      >
        <img
          width="100"
          id="logo-ehp"
          src="@/assets/images/pass-color-logo.png"
          alt="pass-logo"
        />
      </div>
      <SidebarToggler
        :active="show"
        data-test-id="mobile-sidebar-toggler"
        @click="$store.commit('layout/set', ['sidebarShow', !show])"
      />
    </div>

    <UserBoard v-if="user" :user="user"> </UserBoard>

    <CustomScrollbar class="v3-sidebar-items-container" :default-style="true">
      <div data-test-id="sidebar" class="d-flex flex-column pb-6 mb-6">
        <AppSidebarNav
          v-if="filterSideBarItemsByRole"
          :sidebar-items="filterSideBarItemsByRole"
        />
        <CBadge class="mx-auto mt-3 mb-6 bg-gray">
          {{ env + " - " + version }}
        </CBadge>
      </div>
    </CustomScrollbar>
  </CSidebar>
</template>

<script>
import visitorConstants from "@/constants/visitorConstants"
import { shouldVisitorWebAppBeHidden } from "@/helpers/featureFlags"
import { mapGetters } from "vuex"
import version from "../../../package"
import AppSidebarNav from "./AppSidebarNav.vue"
import SidebarItems from "./SidebarItems.vue"
import SidebarToggler from "./SidebarToggler.vue"
import UserBoard from "./UserBoard.vue"

export default {
  name: "TheSidebar",
  components: { AppSidebarNav, SidebarToggler, UserBoard },
  mixins: [SidebarItems],
  data() {
    return {
      componentKey: 0
    }
  },
  computed: {
    ...mapGetters({
      userRole: "authentication/getUserRole",
      user: "authentication/user",
      releases: "updates/releases",
      activeModulesNames: "schools/activeModulesNames"
    }),
    env() {
      return import.meta.env.VITE_DD_ENV
    },
    version() {
      return version.version
    },
    show() {
      return !!this.$store.state.layout.sidebarShow
    },
    minimize() {
      return this.$store.state.layout.sidebarMinimize
    },
    newReleases() {
      return this.releases ? this.releases.filter((e) => e.seen == false) : []
    },
    showPopup() {
      return this.releases
        ? this.releases.filter((e) => e.seen == false).length > 0 &&
            !this.alreadypresented
        : false
    },
    filterSideBarItemsByRole() {
      let items = JSON.parse(JSON.stringify(this.sidebarItems))
      //filter by required Role
      items[0]._children = items[0]._children.filter((item) =>
        item.requiredRoles ? item.requiredRoles.includes(this.userRole) : true
      )
      //filter by required Module
      items[0]._children = items[0]._children.filter((item) => {
        if (item.requiredModule) {
          if (item.requiredModule === visitorConstants.MODULE) {
            const keepVisitorHidden = shouldVisitorWebAppBeHidden()
            return (
              !keepVisitorHidden &&
              this.activeModulesNames.includes(item.requiredModule)
            )
          }
          return this.activeModulesNames.includes(item.requiredModule)
        } else {
          return true
        }
      })

      //filter by Route Dependency
      items[0]._children = items[0]._children.filter((item) =>
        item.routeDependency ? this.$store.getters[item.routeDependency] : true
      )

      items[0]._children.map((item) => {
        if (item.items) {
          item.items = item.items.filter((item) =>
            item.requiredRoles
              ? item.requiredRoles.includes(this.userRole)
              : true
          )
          item.items = item.items.filter((item) => {
            if (item.requiredModule) {
              if (item.requiredModule === visitorConstants.MODULE) {
                const keepVisitorHidden = shouldVisitorWebAppBeHidden()

                return (
                  !keepVisitorHidden &&
                  this.activeModulesNames.includes(item.requiredModule)
                )
              }
              return this.activeModulesNames.includes(item.requiredModule)
            } else {
              return true
            }
          })
          item.items = item.items.filter((item) =>
            item.routeDependency
              ? this.$store.getters[item.routeDependency]
              : true
          )
        }
      })
      items = [...items]
      return items[0]._children
    }
  },
  watch: {
    activeModulesNames: function () {
      // used to force reactivity in filterSideBarItemsByRole and re-render the sidebar after module change
      this.$store.commit("authentication/SET_USER", { ...this.user })
    },
    filterSideBarItemsByRole: function () {
      this.forceComponentReRender()
    }
  },
  mounted() {
    const els = document.querySelectorAll(".nav-link")
    if (els) {
      els.forEach((e) => {
        e.addEventListener(
          "touchend",
          () => {
            window.location.href = e.getAttribute("href")
          },
          false
        )
      })
    }
    this.handleSidebarVisibility()
  },
  methods: {
    forceComponentReRender() {
      //TODO: To create a solution for re-rendering the sidebar on sidebar items change (because of the badges and Pass modules avail.)
      this.componentKey += 1
    },
    handleSidebarVisibility() {
      setTimeout(() => {
        this.$store.commit("layout/set", [
          "sidebarShow",
          window.innerWidth > 992
        ])
      }, 0)
      window.addEventListener("resize", (e) => {
        const windowWidth = e.target.innerWidth
        if (windowWidth) {
          this.$store.commit("layout/set", ["sidebarShow", windowWidth > 992])
        }
      })
    }
  }
}
</script>
