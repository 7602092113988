import visitorConstants from "@/constants/visitorConstants"
import store from "@/store/index"

const v3FeatureFlags = {
  views: {
    AcknowledgedPass: false,
    AdminGuide: false,
    Dashboard: false,
    ForgottenPassword: false,
    Locked: false,
    Login: false,
    QuickReference: false,
    ResetPassword: false,
    SubstituteUsers: true,
    TeacherGuide: false,
    Users: false
  },
  components: {
    HeaderActions: true,
    StudentLatePass: false,
    Banners: true
  }
}

const isV3Active = () => {
  // here you can add extra conditions
  // prod condition for switching from v3 to v2 (for everything):
  // return !appConfig.isProd
  return true
}
const isV3ViewVisible = (viewName = "") => {
  return !isV3Active()
    ? false
    : Object.hasOwn(v3FeatureFlags.views, viewName) &&
        v3FeatureFlags.views[viewName]
}

const isV3ComponentVisible = (componentName = "") => {
  return !isV3Active()
    ? false
    : Object.hasOwn(v3FeatureFlags.components, componentName) &&
        v3FeatureFlags.components[componentName]
}

const shouldVisitorWebAppBeHidden = () => {
  const keepVisitorHidden =
    import.meta.env.VITE_KEEP_VISITOR_HIDDEN === "true" || false
  const areAllSchoolsAllowed =
    import.meta.env.VITE_ALLOW_VISITOR_FOR_ALL_SCHOOLS === "true" || false
  /**
   * When all schools are allowed to use the visitor web app,
   * we hide the visitor web app based on the `keepVisitorHidden` flag.
   */
  if (areAllSchoolsAllowed) {
    return keepVisitorHidden
  } else {
    const activeSchoolId = store?.getters?.["schools/activeSchool"]?.id || 0
    const isCurrentSchoolAllowed =
      visitorConstants.ALLOWED_VISITOR_SCHOOL_IDS.includes(
        Number(activeSchoolId)
      )
    /**
     * When all schools are not allowed to use the visitor web app,
     * then hide the visitor web app if the active school is not in the list of allowed schools.
     */
    return !isCurrentSchoolAllowed
  }
}

export { isV3ViewVisible, isV3ComponentVisible, shouldVisitorWebAppBeHidden }
