<template>
  <div class="pass-history-view v3-pass-history">
    <Loader :is-processing="state.isLoading" />
    <ActionPanel
      @tab-clicked="setActiveTab"
      class="mb-3"
      show-sort
      show-export
      show-search
      @on-search="searchByQuery"
      :tabs="activeTableTabs"
      @on-sort="toggleAdvancedFiltersPanel"
      @on-export="exportCSV"
    >
      <template #right-last-content>
        <Dropdown
          auto-close="outside"
          @update:model-value="onColumnOptionsChange"
          :items="tableDropdowns.column"
          :check-list-mode="true"
          class="left-box-separator"
        >
          <template #toggler>
            <div class="d-flex align-items-center">
              <IconButton class="static" icon="ri-table-line"></IconButton>
              <span>Columns</span>
            </div>
          </template>
        </Dropdown>

        <Dropdown
          v-model="state.isTabular"
          reduce
          :items="tableDropdowns.tableView"
          :preselected="tableDropdowns.tableView[0]"
          class="ms-2 me-2 left-box-separator"
        >
          <template #toggler>
            <div class="d-flex align-items-center">
              <IconButton class="static" icon="ri-layout-row-line"></IconButton>
              <span>View</span>
            </div>
          </template>
        </Dropdown>
      </template>
    </ActionPanel>

    <div class="p-2" v-show="state.isAdFiltersPanelVisible">
      <div class="d-flex">
        <LazyLoadSelect
          :is-multiple="true"
          class="flex-1 me-2"
          :selected="state.filterParams.by_students"
          type="student"
          placeholder="Search student(s)"
          select-only-names
          :show-inactive="true"
          @changed="
            (value) => {
              state.filterParams.by_students = value
            }
          "
        />
        <LazyLoadSelect
          :reports="true"
          :is-multiple="true"
          class="flex-1 me-2"
          :selected="state.filterParams.by_teachers"
          type="adults"
          placeholder="Search teacher(s)"
          select-only-names
          :show-inactive="true"
          @changed="
            (value) => {
              state.filterParams.by_teachers = value
            }
          "
        />
        <LazyLoadSelect
          :is-multiple="true"
          :is-processing="false"
          class="flex-1 me-2"
          :selected="state.filterParams.by_rooms"
          type="locations"
          placeholder="Search room(s)"
          @changed="
            (value) => {
              state.filterParams.by_rooms = value
            }
          "
        />
        <CustomSelect
          v-if="gradeYears"
          v-model="state.filterParams.grade_years"
          class="ps__child--consume flex-1 me-2"
          :options="gradeYears"
          placeholder="Select year"
          :clearable="false"
          :searchable="false"
          @update:model-value="setCalendarGradeYears"
          :close-on-select="true"
        ></CustomSelect>
        <CustomSchoolYearSelectDatePicker
          class="tardy-report-datatable-container-date-picker flex-1"
          v-model="state.filterParams.dates"
          :range-options="optionalDateRanges"
          :is-current-school-year-selected="currentSchoolYearSelected"
          :selected-school-year-limit="state.filterParams.grade_years"
        ></CustomSchoolYearSelectDatePicker>
      </div>
      <div class="mt-4">
        <CustomCheck
          v-if="passMinTime"
          :label="passMinTime"
          v-model="state.filterParams.checkBoxes.yellow_time"
          name="Extended Passes"
          @update:model-value="requestPassHistory()"
          class="me-3 min-10"
        />
        <CustomCheck
          v-for="(status, i) in passStatusCheckboxes"
          :key="i"
          :label="status.label"
          :name="status.label"
          v-model="state.filterParams.checkBoxes[status.value]"
          class="me-3"
          :class="status.value"
          @update:model-value="requestPassHistory()"
        />
        <template v-if="isTardyAllowedForCurrentUser">
          <CustomCheck
            v-for="(status, i) in tardyPassStatusCheckboxes"
            :key="i"
            :label="status.label"
            :name="status.label"
            v-model="state.filterParams.checkBoxes[status.value]"
            class="me-3"
            :class="status.value"
            @update:model-value="requestPassHistory()"
          />
        </template>
      </div>
      <div class="text-end mt-2 mb-3">
        <BaseButton rounded class="me-1" @click="clearFilter"
          >Clear All</BaseButton
        >
        <BaseButton rounded solid @click="advancedFilter">Submit</BaseButton>
      </div>
    </div>

    <PerPage
      v-if="state.pagination"
      :pagination="state.pagination"
      :showing-entries="true"
      @on-page-change="setPerPage"
    />

    <DataTable
      id="pass-history-datatable"
      class="datatable bg-white text-center mt-3"
      :fields="activeTableFields"
      :items="passes"
      :no-items-view="{
        noResults: 'No items available',
        noItems: 'No items available'
      }"
      :sorter="{ external: true }"
      :column-filter="state.isTabular?.value"
      @update:sorter-value="sortByColumn"
    >
      <template #pass_flag>
        <td style="min-width: 60px !important"></td>
      </template>

      <template #user="{ item }">
        <td class="ps-0">
          <UserHistoryPassStatusBox :pass="item" />
        </td>
      </template>
      <template #student_sis_id="{ item }">
        <td>
          {{
            item.user &&
            item.user.student_sis_id !== null &&
            item.user.student_sis_id !== ""
              ? item.user.student_sis_id
              : "null"
          }}
        </td>
      </template>
      <template #created_date="{ item }">
        <td>{{ item.created_date ? item.created_date : "-" }}</td>
      </template>
      <template #created_date-filter>
        <input
          v-model="state.columnSearchKeys.created_date.value"
          placeholder="Search by date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>
      <template #total_time="{ item }">
        <td>
          <b>{{ item.total_time ? item.total_time : "-" }}</b>
        </td>
      </template>
      <template #total_time-filter>
        <input
          v-model="state.columnSearchKeys.total_time.value"
          placeholder="Search by total time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #first_name-filter>
        <input
          v-model="state.columnSearchKeys.first_name.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #last_name-filter>
        <input
          v-model="state.columnSearchKeys.last_name.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #email-filter>
        <input
          v-model="state.columnSearchKeys.email.value"
          placeholder="Search by email"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #out_location-filter>
        <input
          v-model="state.columnSearchKeys.out_location.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #out_teacher-filter>
        <input
          v-model="state.columnSearchKeys.out_teacher.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #out_time-filter>
        <input
          v-model="state.columnSearchKeys.out_time.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #in_location-filter>
        <input
          v-model="state.columnSearchKeys.in_location.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #in_teacher-filter>
        <input
          v-model="state.columnSearchKeys.in_teacher.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #in_time-filter>
        <input
          v-model="state.columnSearchKeys.in_time.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #child_out_location-filter>
        <input
          v-model="state.columnSearchKeys.child_out_location.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #child_out_teacher-filter>
        <input
          v-model="state.columnSearchKeys.child_out_teacher.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #child_out_time-filter>
        <input
          v-model="state.columnSearchKeys.child_out_time.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #child_in_location-filter>
        <input
          v-model="state.columnSearchKeys.child_in_location.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #child_in_teacher-filter>
        <input
          v-model="state.columnSearchKeys.child_in_teacher.value"
          placeholder="Search by name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #child_in_time-filter>
        <input
          v-model="state.columnSearchKeys.child_in_time.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #type="{ item }">
        <td>
          <PassTypeIcon :pass="item" />
        </td>
      </template>
      <template #type-filter>
        <input
          v-model="state.columnSearchKeys.type.value"
          placeholder="Search by type"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #comments="{ item }">
        <td>
          <PassComments
            :pass="item"
            :is-tabular="state.isTabular?.value"
            commentable-type="App\Models\Pass"
            @comment-created="onCommentCreated"
            style="cursor: pointer"
          />
        </td>
      </template>
      <template #comments-filter>
        <input
          v-model="state.columnSearchKeys.comments.value"
          placeholder="Search by comments"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #expired_at="{ item }">
        <td>
          {{ item.expired_at ? $helpers.transformDate(item.expired_at) : "-" }}
        </td>
      </template>
      <template #expired_at-filter>
        <input
          v-model="state.columnSearchKeys.expired_at.value"
          placeholder="Search by date"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #approved_at="{ item }">
        <td class="pass-time-info">
          <b>{{ item.from ? item.from.name : "-" }}</b>
          <p class="my-1 mx-0 p-0">
            {{
              item.out_date_time
                ? $helpers.transformDate(item.out_date_time, "h:mm A")
                : "-"
            }}
          </p>
          <InOutColumn
            :editable-pass-time="true"
            :pass="item"
            section="approved_at"
          />
        </td>
      </template>
      <template #approved_at-filter>
        <input
          v-model="state.columnSearchKeys.approved_at.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #completed_at="{ item }">
        <td class="pass-time-info in-time">
          <b>{{ item.to ? item.to.name : "-" }}</b>
          <p class="my-1 mx-0 p-0">
            {{
              item.completed_at
                ? $helpers.transformDate(item.completed_at, "h:mm A")
                : "-"
            }}
          </p>
          <InOutColumn
            :editable-pass-time="true"
            :pass="item"
            section="completed_at"
          />
        </td>
      </template>

      <template #completed_at-filter>
        <input
          v-model="state.columnSearchKeys.completed_at.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #child_approved_at="{ item }">
        <td class="pass-time-info">
          <b>{{
            item.child && item.child.from ? item.child.from.name : "-"
          }}</b>
          <p class="my-1 mx-0 p-0">
            {{
              item.child && item.child.approved_at
                ? $helpers.transformDate(item.child.approved_at, "h:mm A")
                : "-"
            }}
          </p>
          <InOutColumn
            :editable-pass-time="true"
            :pass="item"
            section="child_approved_at"
          />
        </td>
      </template>
      <template #child_approved_at-filter>
        <input
          v-model="state.columnSearchKeys.child_approved_at.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #child_completed_at="{ item }">
        <td class="pass-time-info in-time">
          <b>{{ item.child && item.child.to ? item.child.to.name : "-" }}</b>
          <p class="my-1 mx-0 p-0">
            {{
              item.child && item.child.completed_at
                ? $helpers.transformDate(item.child.completed_at, "h:mm A")
                : "-"
            }}
          </p>
          <InOutColumn
            :editable-pass-time="true"
            :pass="item"
            section="child_completed_at"
          />
        </td>
      </template>
      <template #child_completed_at-filter>
        <input
          v-model="state.columnSearchKeys.child_completed_at.value"
          placeholder="Search by time"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>
    </DataTable>
    <Pagination
      v-if="
        state.pagination.pages &&
        state.pagination.pages > 1 &&
        !state.isLazyLoadingMode
      "
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    ></Pagination>
  </div>
</template>

<script>
import { onMounted, computed, reactive, onUnmounted } from "vue"
import { useStore } from "vuex"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import PassTypeIcon from "@/v3components/shared/PassComponents/PassTypeIcon.vue"
import Dropdown from "@/v3components/shared/Buttons/Dropdown.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import CustomSchoolYearSelectDatePicker from "@/v3components/shared/Form/CustomSchoolYearSelectDatePicker.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import PassComments from "@/v3components/shared/PassComponents/PassComments.vue"
import UserHistoryPassStatusBox from "@/v3components/shared/PassComponents/UserHistoryPassStatusBox.vue"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import moment from "moment-timezone"
import helpers from "@/helpers/index.js"
import download from "@/helpers/downloadCSV.js"
import tableStaticData from "./static-data.js"

export default {
  name: "HistoryTable",
  components: {
    ActionPanel,
    DataTable,
    Pagination,
    PerPage,
    Dropdown,
    IconButton,
    LazyLoadSelect,
    CustomSchoolYearSelectDatePicker,
    CustomSelect,
    BaseButton,
    CustomCheck,
    PassComments,
    PassTypeIcon,
    UserHistoryPassStatusBox,
    Loader
  },
  setup() {
    const store = useStore()
    const state = reactive({
      defaultActiveTab: {
        label: "My passes",
        value: "only_my_passes"
      },
      showStudentNumber: false,
      isLazyLoadingMode: false,
      isLoading: false,
      isTabular: null,
      searchTimeOut: null,
      isAdFiltersPanelVisible: true,
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      calendar: {
        isCalendarVisible: false,
        isRangesVisible: false,
        activeRange: {
          label: "Today",
          range: {
            start: moment().format("MMM DD YYYY"),
            end: moment().format("MMM DD YYYY")
          }
        }
      },
      filterParams: {
        search_query: "",
        sort_query: "",
        by_students: [],
        by_teachers: [],
        by_rooms: [],
        dates:
          moment().format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY"),
        grade_years: [],
        activeTab: null,
        checkBoxes: {
          edited: false,
          system_ended: false,
          extended_time: false,
          yellow_time: false,
          only_canceled_passes: false,
          missed_request: false,
          only_missed_passes: false,
          late_to_school: false,
          late_to_class: false,
          limit_exceeded: false
        }
      },
      columnSearchKeys: {
        globalQuery: "",
        created_date: {
          value: "",
          column: ["tabular_view.out_date"],
          sortBy: "out_date_time"
        },
        user: { value: "", column: ["user.name"], sortBy: "user.last_name" },
        first_name: {
          value: "",
          column: ["user.first_name"],
          sortBy: "user.first_name"
        },
        last_name: {
          value: "",
          column: ["user.last_name"],
          sortBy: "user.last_name"
        },
        student_sis_id: {
          value: "",
          column: ["student_sis_id"],
          sortBy: "student_sis_id"
        },
        email: { value: "", column: ["user.email"], sortBy: "user.email" },

        out_location: { value: "", column: ["from.name"], sortBy: "from.name" },
        out_teacher: {
          value: "",
          column: ["approved_by_user.name"],
          sortBy: "approved_by_user.name"
        },
        out_time: {
          value: "",
          column: ["tabular_view.out_time"],
          sortBy: "out_time"
        },
        in_location: { value: "", column: ["to.name"], sortBy: "to.name" },
        in_teacher: {
          value: "",
          column: ["completed_by_user.first_name"],
          sortBy: "completed_by_user.first_name"
        },
        in_time: {
          value: "",
          column: ["tabular_view.completed_time"],
          sortBy: "elastic_advanced_fields.completed_time_only"
        },

        child_out_location: {
          value: "",
          column: ["child.from.name"],
          sortBy: "child.from.name"
        },
        child_out_teacher: {
          value: "",
          column: ["child.approved_by_user.first_name"],
          sortBy: "child.approved_by_user.first_name"
        },
        child_out_time: {
          value: "",
          column: ["tabular_view.approve_child_time"],
          sortBy: "elastic_advanced_fields.approve_child_time_only"
        },
        child_in_location: {
          value: "",
          column: ["child.to.name"],
          sortBy: "child.to.name"
        },
        child_in_teacher: {
          value: "",
          column: ["child.completed_by_user.first_name"],
          sortBy: "child.completed_by_user.first_name"
        },
        child_in_time: {
          value: "",
          column: ["tabular_view.completed_child_time"],
          sortBy: "elastic_advanced_fields.completed_child_time_only"
        },

        approved_at: {
          value: "",
          column: ["from.name"],
          sortBy: "out_time"
        },
        completed_at: {
          value: "",
          column: ["to.name"],
          sortBy: "elastic_advanced_fields.completed_time_only"
        },
        child_approved_at: {
          value: "",
          column: ["child.from.name"],
          sortBy: "elastic_advanced_fields.approve_child_time_only"
        },
        child_completed_at: {
          value: "",
          column: ["child.to.name"],
          sortBy: "elastic_advanced_fields.completed_child_time_only"
        },
        total_time: { value: "", column: ["total_time"], sortBy: "total_time" },
        type: { value: "", column: ["type"], sortBy: "type" },
        comments: {
          value: "",
          column: ["comments.comment"],
          sortBy: "created_date"
        },
        expired_at: { value: "", column: ["expired_at"], sortBy: "expired_at" }
      }
    })

    const tardyPassStatusCheckboxes = tableStaticData.tardyPassStatusCheckboxes
    const passStatusCheckboxes = tableStaticData.passStatusCheckboxes
    const tableDropdowns = tableStaticData.tableDropdowns
    const tableTabs = tableStaticData.tableTabs
    const tableFields = tableStaticData.tableFields

    const userRole = computed(() => {
      return store.getters["authentication/getUserRole"]
    })
    const tardyOptions = computed(() => {
      return store.getters["schools/tardyManagementOptions"]
    })
    const user = computed(() => {
      return store.getters["authentication/user"]
    })
    const isTardyAllowedForCurrentUser = computed(() => {
      if (!tardyOptions.value || !tardyOptions.value?.usage?.["within-ehp"]) {
        return false
      }

      if (userRole.value === "admin") {
        return true
      }

      const isTeacherOrStaff = ["staff", "teacher"].includes(userRole.value)
      const allowTeacherStaff =
        tardyOptions.value?.["allow-teacher-staff"] || false
      const allowSelectedUsersOnly =
        tardyOptions.value?.["allow-selected-users-only"] || false

      if (isTeacherOrStaff && allowTeacherStaff) {
        return true
      }

      if (user.value?.allow_tardy && allowSelectedUsersOnly) {
        return true
      }

      return false
    })
    const activeTableTabs = computed(() => {
      return tableTabs.filter((tab) =>
        !isUserInTransparency.value ? tab.value !== "all_passes" : tab
      )
    })

    const passesData = computed(() => {
      return store.getters["passes/passHistories"]
    })

    const isUserInTransparency = computed(() => {
      return store.getters["authentication/isUserInTransparency"]
    })

    const gradeYears = computed(() => {
      const gradeYears = []
      const currentYear = new Date().getFullYear()
      const today = moment().format("YYYY-MM-DD HH:mm:ss")
      const newSchoolYear = moment(today).isAfter(
        moment(`${currentYear}-06-30 23:59:59`)
      )
      if (moment(today).year() == currentYear && newSchoolYear) {
        for (let i = 0; i <= currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      } else {
        for (let i = 0; i < currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      }
      return gradeYears
    })

    const currentSchoolYearSelected = computed(() => {
      const currentYearIndex = gradeYears.value.length - 1
      return (
        state.filterParams.grade_years?.label ==
        gradeYears.value[currentYearIndex].label
      )
    })

    const maxDate = computed(() => {
      if (state.filterParams.grade_years?.value) {
        return new Date(state.filterParams.grade_years.value[1], 5, 30)
      }
      return new Date()
    })
    const minDate = computed(() => {
      if (state.filterParams.grade_years?.value) {
        return new Date(state.filterParams.grade_years.value[0], 6, 1)
      }
      return new Date()
    })

    const optionalDateRanges = computed(() => {
      if (state.filterParams.grade_years?.value && minDate.value) {
        return [
          {
            label: "Today",
            id: "",
            range: helpers.stringDateRange(moment(), moment())
          },
          {
            label: "Yesterday",
            id: "",
            range: helpers.stringDateRange(
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            )
          },
          {
            label: "Last 7 days",
            id: "",
            range: helpers.stringDateRange(
              moment().subtract(7, "days") < moment(minDate.value)
                ? moment(minDate.value)
                : moment().subtract(7, "days"),
              moment()
            )
          },
          {
            label: "Last 14 days",
            id: "",
            range: helpers.stringDateRange(
              moment().subtract(14, "days") < moment(minDate.value)
                ? moment(minDate.value)
                : moment().subtract(14, "days"),
              moment()
            )
          },
          {
            label: "Last 30 days",
            id: "",
            range: helpers.stringDateRange(
              moment().subtract(30, "days") < moment(minDate.value)
                ? moment(minDate.value)
                : moment().subtract(30, "days"),
              moment()
            )
          },
          {
            label: "This month",
            id: "",
            range: helpers.stringDateRange(
              moment().startOf("month"),
              moment().endOf("month")
            )
          },
          {
            label: "This year",
            id: "",
            range:
              helpers.currTzDate(minDate.value) +
              " - " +
              helpers.currTzDate(maxDate.value)
          },
          {
            label: "Custom range",
            range: null,
            startDate: helpers.currTzDate(minDate.value),
            endDate: helpers.currTzDate(maxDate.value),
            id: "custom"
          }
        ]
      }
      return [
        {
          label: "Today",
          id: "",
          range: helpers.stringDateRange(moment(), moment())
        }
      ]
    })

    const onColumnOptionsChange = (value) => {
      state.showStudentNumber = value.includes("student_id")
    }

    const passes = computed(() => {
      return passesData.value
        ? passesData.value.map((item) => {
            return {
              ...item,
              pass_flag: true,
              first_name: item.user ? item.user.first_name : "",
              last_name: item.user ? item.user.last_name : "",
              email: item.user ? item.user.email : "",
              out_location: item.from ? item.from.name : "-",
              out_teacher: item.approved_by_user
                ? item.approved_by_user.name
                : "-",
              out_time: item.out_date_time
                ? helpers.transformDate(item.out_date_time, "h:mm:ss A")
                : "-",
              in_location: item.to ? item.to.name : "-",
              in_teacher: item.completed_by_user
                ? item.completed_by_user.name
                : "-",
              in_time: item.completed_at
                ? helpers.transformDate(item.completed_at, "h:mm:ss A")
                : "-",
              child_out_location:
                item.child && item.child.from ? item.child.from.name : "-",
              child_out_teacher:
                item.child && item.child.approved_by_user
                  ? item.child.approved_by_user.name
                  : "-",
              child_out_time:
                item.child && item.child.approved_at
                  ? helpers.transformDate(item.child.approved_at, "h:mm:ss A")
                  : "-",
              child_in_location:
                item.child && item.child.to ? item.child.to.name : "-",
              child_in_teacher:
                item.child && item.child.completed_by_user
                  ? item.child.completed_by_user.name
                  : "-",
              child_in_time:
                item.child && item.child.completed_at
                  ? helpers.transformDate(item.child.completed_at, "h:mm:ss A")
                  : "-",

              child_approved_at:
                item.child && item.child.from ? item.child.from.name : "-",
              child_completed_at:
                item.child && item.child.to ? item.child.to.name : "-",
              student_sis_id: item.user ? item.user.student_sis_id : ""
            }
          })
        : passesData.value
    })

    const activeTableFields = computed(() => {
      const fields = tableFields.filter((field) =>
        !state.showStudentNumber ? field.key !== "student_sis_id" : field
      )
      return fields.filter((field) =>
        state.isTabular && state.isTabular.value
          ? field.type !== "pass-view"
          : field.type !== "tabular"
      )
    })

    const passesTimeSettings = computed(() => {
      return store.getters["passes/passesTimeSettings"]
    })

    const passMinTime = computed(() => {
      return passesTimeSettings.value && passesTimeSettings.value.min_time
        ? helpers.formatDate(
            passesTimeSettings.value.min_time,
            "hh:mm:ss",
            "mm"
          ) + "+Min"
        : null
    })

    const setActiveTab = (tab) => {
      if (
        state.filterParams.activeTab &&
        state.filterParams.activeTab.value == tab.value
      ) {
        return
      }
      state.filterParams.activeTab = tab
      if (passes?.value?.length) {
        requestPassHistory()
      }
    }

    const toggleAdvancedFiltersPanel = () => {
      state.isAdFiltersPanelVisible = !state.isAdFiltersPanelVisible
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      requestPassHistory()
    }

    const exportCSV = () => {
      requestPassHistory(state.pagination.activePage, true)
    }

    const limitToCurrentSchoolYear = () => {
      return state.filterParams.dates
        .split(" - ")
        .map((date) => {
          return helpers.isPreviousYearDate(date)
            ? helpers.currTzDate(helpers.minSchoolYearDate())
            : date
        })
        .join(" - ")
    }

    const requestPassHistory = (
      page,
      isExport,
      isLazyLoadingMode,
      noLoader
    ) => {
      state.isLoading = !noLoader
      state.pagination.activePage = page ? page : 1
      const params = {
        only_my_passes: state.filterParams.activeTab.value === "only_my_passes",
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value,
        dates: currentSchoolYearSelected.value
          ? limitToCurrentSchoolYear()
          : state.filterParams.dates
      }
      params.dates = params.dates
        ? params.dates.replace("-", ",")
        : params.dates
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
        if (state.columnSearchKeys.globalQuery) {
          params.global_search = true
        }
      }
      if (
        state.filterParams.by_students &&
        state.filterParams.by_students.length
      ) {
        params.by_students = state.filterParams.by_students
          .map((el) => el.value.id)
          .join()
      }
      if (
        state.filterParams.by_teachers &&
        state.filterParams.by_teachers.length
      ) {
        params.by_teachers = state.filterParams.by_teachers
          .map((el) => el.value.id)
          .join()
      }
      if (state.filterParams.by_rooms && state.filterParams.by_rooms.length) {
        params.by_rooms = state.filterParams.by_rooms
          .map((el) => el.value.id)
          .join()
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      const filters_list = []
      for (const key in state.filterParams.checkBoxes) {
        if (Object.hasOwnProperty.call(state.filterParams.checkBoxes, key)) {
          const item = state.filterParams.checkBoxes[key]
          if (item) {
            filters_list.push('"' + key + '"')
          }
        }
      }
      if (filters_list.length) {
        params.filters_list = "[" + filters_list + "]"
      }

      if (isExport) {
        getCSVPassHistoriesExport(params)
      } else {
        getPassHistories(params, isLazyLoadingMode)
      }
    }
    const getPassHistories = (params, isLazyLoadingMode) => {
      state.isLoading = !isLazyLoadingMode
      store
        .dispatch("passes/getPassHistories", params)
        .then((response) => {
          const data = response.data
          if (data.data) {
            if (isLazyLoadingMode) {
              store.commit("passes/PUSH_PASS_HISTORIES", data.data)
            } else {
              store.commit("passes/SET_PASS_HISTORIES", data.data)
            }
          }
          if (data.meta) {
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
        })
        .finally(() => {
          state.isLoading = false
        })
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            passes.value &&
            passes.value.length &&
            state.pagination.total > passes.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              requestPassHistory(state.pagination.activePage, false, true, true)
            }
          }
        }
      }
    }

    const getCSVPassHistoriesExport = (params) => {
      state.isLoading = true
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      store
        .dispatch("passes/getCSVPassHistoriesExport", {
          ...params,
          student_sis_id: state.showStudentNumber
        })
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "pass-histories")
          }
        })
        .finally(() => {
          state.isLoading = false
        })
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    state.filterParams.search_query +
                    `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        requestPassHistory()
      }
    }

    const onCommentCreated = (comment) => {
      store.commit("passes/UPDATE_PASS_HISTORY_COMMENT", comment)
    }
    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        created_date: {
          value: "",
          column: ["tabular_view.out_date"],
          sortBy: "out_date_time"
        },
        user: { value: "", column: ["user.name"], sortBy: "user.last_name" },
        first_name: {
          value: "",
          column: ["user.first_name"],
          sortBy: "user.first_name"
        },
        last_name: {
          value: "",
          column: ["user.last_name"],
          sortBy: "user.last_name"
        },
        student_sis_id: {
          value: "",
          column: ["student_sis_id"],
          sortBy: "student_sis_id"
        },
        email: { value: "", column: ["user.email"], sortBy: "user.email" },

        out_location: { value: "", column: ["from.name"], sortBy: "from.name" },
        out_teacher: {
          value: "",
          column: ["approved_by_user.name"],
          sortBy: "approved_by_user.name"
        },
        out_time: {
          value: "",
          column: ["tabular_view.out_time"],
          sortBy: "out_time"
        },
        in_location: { value: "", column: ["to.name"], sortBy: "to.name" },
        in_teacher: {
          value: "",
          column: ["completed_by_user.first_name"],
          sortBy: "completed_by_user.first_name"
        },
        in_time: {
          value: "",
          column: ["tabular_view.completed_time"],
          sortBy: "elastic_advanced_fields.completed_time_only"
        },

        child_out_location: {
          value: "",
          column: ["child.from.name"],
          sortBy: "child.from.name"
        },
        child_out_teacher: {
          value: "",
          column: ["child.approved_by_user.first_name"],
          sortBy: "child.approved_by_user.first_name"
        },
        child_out_time: {
          value: "",
          column: ["tabular_view.approve_child_time"],
          sortBy: "elastic_advanced_fields.approve_child_time_only"
        },
        child_in_location: {
          value: "",
          column: ["child.to.name"],
          sortBy: "child.to.name"
        },
        child_in_teacher: {
          value: "",
          column: ["child.completed_by_user.first_name"],
          sortBy: "child.completed_by_user.first_name"
        },
        child_in_time: {
          value: "",
          column: ["tabular_view.completed_child_time"],
          sortBy: "elastic_advanced_fields.completed_child_time_only"
        },

        approved_at: {
          value: "",
          column: ["from.name"],
          sortBy: "out_time"
        },
        completed_at: {
          value: "",
          column: ["to.name"],
          sortBy: "elastic_advanced_fields.completed_time_only"
        },
        child_approved_at: {
          value: "",
          column: ["child.from.name"],
          sortBy: "elastic_advanced_fields.approve_child_time_only"
        },
        child_completed_at: {
          value: "",
          column: ["child.to.name"],
          sortBy: "elastic_advanced_fields.completed_child_time_only"
        },
        total_time: { value: "", column: ["total_time"], sortBy: "total_time" },
        type: { value: "", column: ["type"], sortBy: "type" },
        comments: {
          value: "",
          column: ["comments.comment"],
          sortBy: "created_date"
        },
        expired_at: { value: "", column: ["expired_at"], sortBy: "expired_at" }
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      requestPassHistory(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        requestPassHistory()
      }
    }

    const setCalendarGradeYears = () => {
      state.filterParams.dates = `${helpers.currTzDate(minDate.value)} - ${helpers.currTzDate(maxDate.value)}`

      state.calendar.activeRange = optionalDateRanges.value[6]
    }

    const advancedFilter = () => {
      resetSearchQuery()
      requestPassHistory()
    }

    const clearFilter = () => {
      clearFilterParams()
      requestPassHistory()
    }

    const clearFilterParams = () => {
      state.filterParams = {
        search_query: state.filterParams.search_query,
        sort_query: state.filterParams.sort_query,
        by_students: [],
        by_teachers: [],
        by_rooms: [],
        activeTab: state.filterParams.activeTab,
        dates:
          moment().format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY"),
        grade_years: gradeYears.value
          ? gradeYears.value[gradeYears.value.length - 1]
          : state.filterParams.grade_years,
        checkBoxes: {
          edited: false,
          system_ended: false,
          extended_time: false,
          yellow_time: false,
          only_canceled_passes: false,
          missed_request: false,
          only_missed_passes: false,
          late_to_school: false,
          late_to_class: false,
          limit_exceeded: false
        }
      }
      state.calendar.activeRange = optionalDateRanges.value[0]
    }

    onMounted(() => {
      initLazyLoading()
      state.filterParams.activeTab = state.defaultActiveTab
      store.dispatch("passes/getPassesTimeSetting")
      state.filterParams.grade_years = gradeYears.value
        ? gradeYears.value[gradeYears.value.length - 1]
        : state.filterParams.grade_years
    })

    onUnmounted(() => {
      store.commit("passes/SET_PASS_HISTORIES", [])
    })

    return {
      activeTableFields,
      tableTabs,
      passes,
      setActiveTab,
      toggleAdvancedFiltersPanel,
      exportCSV,
      state,
      sortByColumn,
      searchByQuery,
      setActivePage,
      setPerPage,
      tableDropdowns,
      onColumnOptionsChange,
      gradeYears,
      currentSchoolYearSelected,
      setCalendarGradeYears,
      optionalDateRanges,
      advancedFilter,
      clearFilter,
      passStatusCheckboxes,
      passesTimeSettings,
      passMinTime,
      requestPassHistory,
      activeTableTabs,
      onCommentCreated,
      isTardyAllowedForCurrentUser,
      tardyPassStatusCheckboxes
    }
  }
}
</script>
